/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Subtitle, SeparateLine, SeparateLineWrap, Button, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"akce"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center" style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"bd9iov63ube"} parallax={false}>
        </Column>


        <Column className="pb--60 pt--60" name={"xflqi4x7qh"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/e5ac926f411e4d7394f3c76320d44a2b_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/e5ac926f411e4d7394f3c76320d44a2b_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/e5ac926f411e4d7394f3c76320d44a2b_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/e5ac926f411e4d7394f3c76320d44a2b_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/e5ac926f411e4d7394f3c76320d44a2b_s=1400x_.png 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/cb736e2a1d5a4455a07a50bc02a03b25_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/cb736e2a1d5a4455a07a50bc02a03b25_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/cb736e2a1d5a4455a07a50bc02a03b25_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/cb736e2a1d5a4455a07a50bc02a03b25_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/cb736e2a1d5a4455a07a50bc02a03b25_s=1400x_.png 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/015106c67ddf42efa27b20f80b5edb22_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/015106c67ddf42efa27b20f80b5edb22_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/015106c67ddf42efa27b20f80b5edb22_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/015106c67ddf42efa27b20f80b5edb22_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/015106c67ddf42efa27b20f80b5edb22_s=1400x_.png 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/f37217c009204e5ba299612e4c6ec9eb_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/f37217c009204e5ba299612e4c6ec9eb_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/f37217c009204e5ba299612e4c6ec9eb_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/f37217c009204e5ba299612e4c6ec9eb_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/f37217c009204e5ba299612e4c6ec9eb_s=1400x_.png 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"d8lhfmjq21"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold; color: rgb(6, 35, 243);\">Program organizace v listopadu 2024</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">4.11.2024 - dětský domov Litovel</span> - návštěva dětí&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">9.11.2024 - dětský domov Velké Heraltice</span> - zahájení spolupráce"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">10.11.2024 - dětský domov Vizovice</span> - návštěva dětí&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">13.11.2024 - dětský domov Tisá</span> - zahájení spolupráce"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">14.11.2024 - výchovný ústav Pšov</span> - návštěva dětí&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">15.11.2024 - dětský domov se školou Místo</span> - návštěva dětí&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">21.-24.11.2024 - dětský diagnostický ústav Hradec Králové, dětský domov Černá Voda, dětský domov Krásná Lípa, dětský domov Litovel</span> - podzimní pobyt - Janoušov&nbsp;"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"jzsuu7twozi"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"xqiycxwfbk"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Čtvrtletník organizace za duben, květen, červen 2024 k zobrazení níže.&nbsp;</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5" content={"klikněte sem"} url={"https://www.canva.com/design/DAGFHCLa2B0/Ob64zIyMnTIeG0jHzux9SA/view?utm_content=DAGFHCLa2B0&utm_campaign=designshare&utm_medium=link&utm_source=editor"} href={"https://www.canva.com/design/DAGFHCLa2B0/Ob64zIyMnTIeG0jHzux9SA/view?utm_content=DAGFHCLa2B0&utm_campaign=designshare&utm_medium=link&utm_source=editor"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"rid5jb1nqkn"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"9wcldllov3g"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--48" content={"<span style=\"font-weight: bold; color: rgb(55, 7, 247);\">Letní prázdniny&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box text-box--justify fs--14" style={{"maxWidth":""}} content={"<span style=\"color: var(--black); background-color: rgb(255, 255, 255); font-weight: bold;\">Jako každý rok i letos jsme pořádali řadu úspěšných letních pobytů a akcí pro děti z dětských domovů. Chtěli bychom poděkovat všem, kdo nám jakkoliv pomohli s přípravou a realizací všech letních akcí a pobytů pro děti. Věříme, že jsme společně zpříjemnili léto spoustě dětem z dětských domovů!&nbsp;</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--justify fs--20" content={"<span style=\"font-weight: bold; color: rgb(245, 36, 6);\">Z Krkonoš do Rovečného nebo do Mariánských lázní přes Tachov <br>a z Prostějova do Vrbna pod Pradědem či z Pardubic do Bratislavy.&nbsp;</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify ff--3" style={{"maxWidth":""}} content={"<span style=\"color: var(--black); background-color: rgb(255, 255, 255); font-weight: bold;\">Léto jsme zahájili s dětmi z dětského domova v Litovli, které jsme vzali na náš tradiční jarní pobyt ve Špindlerově Mlýně na Hotel Start. Dále jsme pokračovali s dětmi z DDÚ Hradce Králové na letní pobyt na Vysočině v Rovečném nebo na následný výměnný pobyt mezi dětským domovem Tachov a dětským domovem ve Vrbně pod Pradědem v Plzeňském kraji či dalším výměnným pobytem pro děti z dětského domova v Prostějově se kterými jsme zamířili za dětmi do dětského domova ve Vrbně pod Pradědem nebo dětského domova v Černé Vodě. Dále jsme stihli na přelomu července a srpna uspořádat několikadenní poznávací akci v Bratislavě na Slovensku pro děti z dětského domova v Dolní Čermné či řadu výletů a akcí jako například návštěvu ZOO Dvůr Králové nad Labem nebo IQ Landia v Liberci.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"nk9lkgwoat7"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingBottom":2,"paddingTop":0}} name={"aih8wodcou5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"font-weight: bold;\">Fotogalerie</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"cb14qr4ruzp"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"41y508q0ytj"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/ff5c64e8228742c9a7f858baeb8937e4_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/ff5c64e8228742c9a7f858baeb8937e4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/ff5c64e8228742c9a7f858baeb8937e4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/ff5c64e8228742c9a7f858baeb8937e4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/ff5c64e8228742c9a7f858baeb8937e4_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/1f4cdf215cec4cda98ffea1530ab1b11_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/1f4cdf215cec4cda98ffea1530ab1b11_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/1f4cdf215cec4cda98ffea1530ab1b11_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/1f4cdf215cec4cda98ffea1530ab1b11_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/1f4cdf215cec4cda98ffea1530ab1b11_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/c5a1939a4af943ef908e4c793842262d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/c5a1939a4af943ef908e4c793842262d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/c5a1939a4af943ef908e4c793842262d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/c5a1939a4af943ef908e4c793842262d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/c5a1939a4af943ef908e4c793842262d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/c5a1939a4af943ef908e4c793842262d_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/440611b99a18420a92fa1b843cce9aeb_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/440611b99a18420a92fa1b843cce9aeb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/440611b99a18420a92fa1b843cce9aeb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/440611b99a18420a92fa1b843cce9aeb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/440611b99a18420a92fa1b843cce9aeb_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/7d1845a65ba8434fb68d5810577fe513_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/7d1845a65ba8434fb68d5810577fe513_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/7d1845a65ba8434fb68d5810577fe513_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/7d1845a65ba8434fb68d5810577fe513_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/0021b82387fd409db4cebc74fd8b8f88_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/0021b82387fd409db4cebc74fd8b8f88_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/0021b82387fd409db4cebc74fd8b8f88_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/cda98a6861fc4b768e02a5e7f1709ec3_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/cda98a6861fc4b768e02a5e7f1709ec3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/cda98a6861fc4b768e02a5e7f1709ec3_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/3e1f2c8ede0441d79959ea19806aa0ed_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/3e1f2c8ede0441d79959ea19806aa0ed_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/3e1f2c8ede0441d79959ea19806aa0ed_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/3e1f2c8ede0441d79959ea19806aa0ed_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/3e1f2c8ede0441d79959ea19806aa0ed_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/1dd1c1e6b55445848c2837894f77dd0d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/1dd1c1e6b55445848c2837894f77dd0d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/1dd1c1e6b55445848c2837894f77dd0d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/1dd1c1e6b55445848c2837894f77dd0d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/1dd1c1e6b55445848c2837894f77dd0d_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/6e07c4aaec8040d0b56cb4f9a03dd8a4_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/ca5ae8f2481a4b4e937c0bb84d20c223_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/ca5ae8f2481a4b4e937c0bb84d20c223_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/ca5ae8f2481a4b4e937c0bb84d20c223_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/ca5ae8f2481a4b4e937c0bb84d20c223_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/ca5ae8f2481a4b4e937c0bb84d20c223_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/f49408f7553d40269705c444b6429b52_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/f49408f7553d40269705c444b6429b52_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/f49408f7553d40269705c444b6429b52_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/f49408f7553d40269705c444b6429b52_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/006490e7edfd4497b0467b8fc8a3c9c6_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/006490e7edfd4497b0467b8fc8a3c9c6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/006490e7edfd4497b0467b8fc8a3c9c6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/006490e7edfd4497b0467b8fc8a3c9c6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/006490e7edfd4497b0467b8fc8a3c9c6_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/836d0c37ff2e455f99fd11da4656de66_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/836d0c37ff2e455f99fd11da4656de66_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/836d0c37ff2e455f99fd11da4656de66_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/836d0c37ff2e455f99fd11da4656de66_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/836d0c37ff2e455f99fd11da4656de66_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/b5beb03de9f74bec93153444531bbb74_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/b5beb03de9f74bec93153444531bbb74_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/b5beb03de9f74bec93153444531bbb74_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/b5beb03de9f74bec93153444531bbb74_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/b5beb03de9f74bec93153444531bbb74_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/0aa5fe32e466443999574c6089024ba6_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/0aa5fe32e466443999574c6089024ba6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/0aa5fe32e466443999574c6089024ba6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/0aa5fe32e466443999574c6089024ba6_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}